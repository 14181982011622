@font-face {
  font-family: "Gotham-Black";
  src: url("/public/fonts/Gotham-Black.otf") format("opentype");
}
@font-face {
  font-family: "Gotham-Book";
  src: url("/public/fonts/Gotham-Book.otf") format("opentype");
}
@font-face {
  font-family: "Gotham-Light";
  src: url("/public/fonts/Gotham-Light.otf") format("opentype");
}
@font-face {
  font-family: "Gotham-Medium";
  src: url("/public/fonts/Gotham-Medium.otf") format("opentype");
}

@font-face {
  font-family: "SF-Pro-Display-Light";
  src: url("/public/fonts/SF-Pro-Display-Light.ttf") format("truetype");
}
@font-face {
  font-family: "SF-Pro-Text-Light";
  src: url("/public/fonts/SF-Pro-Text-Light.ttf") format("truetype");
}
@font-face {
  font-family: "SF-Pro-Text-Semibold";
  src: url("/public/fonts/SF-Pro-Text-Semibold.ttf") format("truetype");
}
@font-face {
  font-family: "SFProDisplay-Medium";
  src: url("/public/fonts/SFProDisplay-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "SFProDisplay-Semibold";
  src: url("/public/fonts/SFProDisplay-Semibold.ttf") format("truetype");
}
@font-face {
  font-family: "SFProText-Bold";
  src: url("/public/fonts/SFProText-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "SFProText-Medium";
  src: url("/public/fonts/SFProText-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "SFProText-Regular";
  src: url("/public/fonts/SFProText-Regular.ttf") format("truetype");
}

.slick-track {
  margin-left: unset !important;
  margin-right: unset !important;
}

body {
  margin: 0;
  font-family: "Gotham-Light";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  overflow: hidden;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
}

/* ::-webkit-scrollbar { display: none; }
 */

@media screen {
  ::-webkit-scrollbar {
    display: none;
  }
}

.selectedClass {
  background-color: black;
  color: "white";
}
